import React from "react"
import LayoutMatkustajapalvelut from '../components/layout-matkustajapalvelut';
import FrontPageMatkustajapalvelut from '../components/frontpage-matkustajapalvelut';
import { graphql } from "gatsby";



export default function Home({data}) {
  return (
      <LayoutMatkustajapalvelut children={<FrontPageMatkustajapalvelut serverUrl={data.site.siteMetadata.serverUrl} />} />
  );
}

export const query = graphql`
  query SiteUrlQuery {
    site {
      siteMetadata {
        siteUrl
        serverUrl
        title
        description
      }
    }
  }
`
