import React from 'react';
import HeroImageJPG from '../../static/nonono_hero_2122x1412.png';
import styled from 'styled-components';
import Box  from "@material-ui/core/Box";
import {device, deviceMax} from "../helpers/devicesizes";
import Typography from '@material-ui/core/Typography';
import MatkahuoltoSVG from '../../svg-assets/matkahuolto.svg';

const HeroImage = styled(Box)`
@media ${device.tablet} {
    background: url(${HeroImageJPG});
    background-origin: padding-box;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-color: #9ecfef;
    border: none;
}

@media ${deviceMax.tablet} {
    background: url(${HeroImageJPG});
    background-origin: padding-box;
    background-repeat: no-repeat;
    background-size: contain;
    height: 265px;
    background-position: center center;
    background-color: #9ecfef;
    border: none;
}
`;

const HeroBox = styled(Box)`
    @media ${device.tablet} {
        overflow: hidden;
        max-height: 480px;
    }
    @media ${deviceMax.tablet} {
        overflow: hidden;
        max-height: 265px;
    }
`;

const MatkahuoltoIcon = styled(MatkahuoltoSVG)`
    @media ${device.tablet} {
        height: 73px;
        margin: 30px 0;
    }
    @media ${deviceMax.tablet} {
        width: 60px;
        margin: 0;
        position: absolute;
        right: 10px;
        top: 40px;
    }
`;

const HeroTextBox = styled(Box)`
    @media ${device.tablet} {
        width: 450px;
        padding: 50px;
        margin: auto;
        position: relative;
        right: 200px;
    }
    @media ${deviceMax.tablet} {
        width: 187px;
        padding: 20px 30px;
    }
`;

const TypographyH2 = styled(Typography)`
    color: #06324c;
    font-size:50px;
    font-weight: 700;
    @media ${deviceMax.tablet} {
        font-size: 20px;
    }
`;

const TypographyH5 = styled(Typography)`
    font-size: 19px;
    @media ${deviceMax.tablet} {
        font-size: 12px;
    }
`;
export default function Hero() {
    return (
        <HeroBox>
            <HeroImage>
                <HeroTextBox>
                    <TypographyH2 variant="h1" style={{paddingBottom:'15px'}}>Kat kesiko <span style={{color: '#ea5797'}}>ketju?</span></TypographyH2>
                    <TypographyH5 variant="h5" style={{paddingBottom:'15px'}}>Keräsimme kritiikkiä ja kehitysehdotuksia Reitit ja Liput -sovelluksesta ja sen kautta ostetuista matkaketjuista. Halusimme käydä avointa keskustelua asiakkaiden kanssa.</TypographyH5>
                    <MatkahuoltoIcon />
                </HeroTextBox>
            </HeroImage>
        </HeroBox>
    );
}