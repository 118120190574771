import React from 'react';
import styled from 'styled-components';
import Box  from "@material-ui/core/Box";
import Grid  from "@material-ui/core/Grid";
import {device, deviceMax} from "../helpers/devicesizes";
import Typography from '@material-ui/core/Typography';
import RaportoiImg from '../../static/nonono_raportoi@2x.png';
import KeskusteleImg from '../../static/nonono_keskustele@2x.png';
import ArvosteleImg from '../../static/nonono_arvostele@2x.png';

const StyledBox = styled(Box)`
    text-align:center;
    @media ${device.tablet} {
        padding: 40px 50px 10px 50px;
    }
    @media ${deviceMax.tablet} {
        padding: 25px 11px;
    }
`;

const StyledGrid = styled(Grid)`
    max-width: 650px;
    margin:25px auto;
    text-align: left;
`;

const StyledGridContainer = styled(Grid)`
    height: 70px;
`;

const StyledImage = styled.img`
    width: 70px;
    display:block;
`;
/*
const KeskusteleImage = styled(KeskusteleImg)`
    width: 70px;
    display:block;
`;

const ArvosteleImage = styled(ArvosteleImg)`
    width: 70px;
    display:block;
`;*/

const FlexBox = styled(Box)`
    @media ${device.tablet} {
        margin-top: 20px;
    }
    @media ${deviceMax.tablet} {
        margin-top: 10px;
    }
`;

const FlexBoxItem = styled(FlexBox)`
    width: 200px;
    @media ${device.tablet} {
        display: flex;
    }
    @media ${deviceMax.tablet} {
        display: block;
    }
`;

const FlexBoxItemChild = styled.div`
@media ${device.tablet} {
    text-align: left;
    margin-left: 10px;
}
@media ${deviceMax.tablet} {
    text-align: center;
    height:100px;
}
`;

const FlexBoxItemChildImg = styled.div` 
@media ${device.tablet} {
    text-align: left;
    margin-left: 10px;
}
@media ${deviceMax.tablet} {
    text-align: center;
    

    img {
        display:block;
        width: 70px;
        height:70px;
        margin:auto;
    }
}
`;

const TypographyH5 = styled(Typography)`
    @media ${deviceMax.tablet} {
        font-size: 18px;
    }
`;

export default function CampaignPhasesMatkustaja() {
    return (
        <StyledBox bgcolor="secondary.main">
            <TypographyH5 variant="h5">Näin kampanja toimi:</TypographyH5>
            <FlexBox display={'flex'} justifyContent={"center"} alignItems={"center"}>
                <FlexBoxItem>
                    <FlexBoxItemChildImg>
                        <StyledImage src={RaportoiImg} />
                    </FlexBoxItemChildImg>
                    <FlexBoxItemChild>
                        <Typography color="primary">1. <strong>Asiakas</strong> raportoi ongelman</Typography>
                    </FlexBoxItemChild>
                </FlexBoxItem>
                <FlexBoxItem>
                    <FlexBoxItemChildImg>
                        <StyledImage src={KeskusteleImg} />
                    </FlexBoxItemChildImg>
                    <FlexBoxItemChild>
                        <Typography color="primary">2. <strong>Matkahuolto</strong> vastasi</Typography>
                    </FlexBoxItemChild>
                </FlexBoxItem>
                <FlexBoxItem>
                    <FlexBoxItemChildImg>
                        <StyledImage src={ArvosteleImg} />
                    </FlexBoxItemChildImg>
                    <FlexBoxItemChild>
                        <Typography color="primary">3. <strong>Asiakas</strong> arvioi palvelun</Typography>
                    </FlexBoxItemChild>
                </FlexBoxItem>
            </FlexBox>
        </StyledBox>
    );
}