import React from "react";
import Hero from "./hero-matkustajapalvelut";
import CampaignPhases from "./campaign-phases-matkustajapalvelut";
import Button  from "@material-ui/core/Button";
import Box  from "@material-ui/core/Box";
import {navigate} from 'gatsby';
import styled from 'styled-components';
import {device, deviceMax} from "../helpers/devicesizes";

const StyledBox = styled(Box)`
    padding-bottom: 50px;
    background-color: #fff;
    @media ${device.tablet} {
        padding-top: 20px;
    }
    @media ${deviceMax.tablet} {
        padding-top: 0px;
    }
`;

export default function FrontPageMatkustajapalvelut({serverUrl}) {

    const popUp = (e) => {
        navigate('/matkahuolto-kokemuksia/');
    }
    return (
        <React.Fragment>
            <Hero></Hero>
            <CampaignPhases></CampaignPhases>
            <StyledBox display="flex" justifyContent="center" alignItems="center">
                <Button variant="contained" size="large" color="primary" onClick={popUp}>Tästä lukemaan keskusteluja</Button>
            </StyledBox>
        </React.Fragment>

    );

}